import React from "react"
import HomePage from "./HomePage";
const apiEndpoint = 'https://prad.prismic.io/api/v2';

class Index extends React.Component{
    constructor(props){
      super(props);
      this.timeout = null;      
      this.state = { isAdvertisement: true }
    }

    skipAdvertisement = () => {
      const ele = document.getElementById('globalDummyDiv');
      if (ele && ele.outerHTML != '') {
        ele.outerHTML = '';
      }
      this.setState({ isAdvertisement: false });
    }

    componentDidMount(){
      // This global dummy div used to check if user comes back from article to home page
      // then again it should not load advertisement banner, so to handle this, we are maintaining
      // global dummmy tag, only when page loads, then only advertisement banner will visible
      const ele = document.getElementById('globalDummyDiv');
      if (ele && ele.outerHTML != '') {
        this.timeout = setTimeout(
          function() {
              const skipDeletedObjExist = document.getElementById('globalDummyDiv');
              if (skipDeletedObjExist && skipDeletedObjExist.outerHTML != '') {
                ele.outerHTML = '';
              }
              this.setState({ isAdvertisement: false });
          }
          .bind(this),
          20000
        );        
      }else {
        this.setState({ isAdvertisement: false });
      }
    }

    componentWillUnmount() {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
      }
    }    

    render(){
        if (this.state.isAdvertisement) {
          return(
            <div id="ipl-progress-indicator">
              <center><a href="#" onClick={this.skipAdvertisement}>
                  Continue to Site</a></center>
              <a href="https://zyppys.com/">
                <img id="imgcss" src="http://www.zyppys.co.in/images/AHL_Marketing_Zyppys_2024.jpg"/>
              </a>
            </div>        
          );
        }else{
          return(
            <HomePage {...this.props}/>
          );
        }  
    }
}

export default Index;

export const pageQuery = graphql`
query{
  all: allPrismicArticle(filter: {tags: {ne: "ahl"}, data: { active: {eq: "Y"}} }, sort: { fields: [data___datetime], order: DESC }, limit:30){
    edges{
      node{
        tags
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  featured: allPrismicArticle(filter: { tags: {eq: "featured"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  politics: allPrismicArticle(filter: { tags: {eq: "politics"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  entertainment: allPrismicArticle(filter: { tags: {eq: "entertainment"}, data: { active: {eq: "Y"}} }, limit:5){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  tech: allPrismicArticle(filter: { tags: {eq: "tech"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  fashion: allPrismicArticle(filter: { tags: {eq: "fashion"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  business: allPrismicArticle(filter: { tags: {eq: "business"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  health: allPrismicArticle(filter: { tags: {eq: "health"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  nri: allPrismicArticle(filter: { tags: {eq: "nri"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  gallery: allPrismicGalleryarticle(filter: { tags: {eq: "gallery"}, data: { active: {eq: "Y"}} }, sort: { fields: [last_publication_date], order: DESC }, limit:20){
    edges{
      node{
        tags
        last_publication_date
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
        }
        uid
      }
    }
  }

  one: allPrismicArticle(filter: { tags: {eq: "one"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  two: allPrismicArticle(filter: { tags: {eq: "two"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  three: allPrismicArticle(filter: { tags: {eq: "three"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  four: allPrismicArticle(filter: { tags: {eq: "four"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  five: allPrismicArticle(filter: { tags: {eq: "five"}, data: { active: {eq: "Y"}} }, limit:1){
    edges{
      node{
        data{
          third_party_url
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }

  review: allPrismicReviewarticle(filter: { data: { active: {eq: "Y"}} }, sort: { fields: [data___datetime], order: DESC }, limit:20){
    edges{
      node{
        tags
        data{
          title{
            text
          }
          content{
            text
          }
          img{
            url
          }
          datetime
          embed{
            embed_url
          }
        }
        uid
      }
    }
  }  
}
`
